/**
 * アンカーリンクをクリックした際にアニメーションつきでスクロールさせる
 * @example
 * ```jsx
 * <a href="#section" onClick={onAnchorLinkClickSmoothScroll}>...</a>
 * ```
 */
export const onAnchorLinkClickSmoothScroll = (
  e: React.MouseEvent<HTMLAnchorElement>
) => {
  try {
    const anchor = new URL(e.currentTarget.href).hash.slice(1)
    if (!anchor) return
    const target = document.getElementById(anchor)
    if (!target) return
    target.scrollIntoView({ behavior: 'smooth' })
    e.preventDefault()
  } catch {
    // noop
  }
}
