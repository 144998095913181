import { createContext, useContext } from 'react'
import { getUrlByDomainCode } from '../domain'
import { DomainCode } from '../types'

const DomainContext = createContext<DomainCode>('jp')

export const useDomainCode = () => useContext(DomainContext)

export const useBaseUrl = (): URL => getUrlByDomainCode(useDomainCode())

export const appWithDomainContext = <T extends Record<string, unknown>>(
  App: React.FC<T>
): React.FC<T> => {
  const AppWithDomain: React.FC<T> = (props) => {
    return (
      <DomainContext.Provider value="jp">
        <App {...props} />
      </DomainContext.Provider>
    )
  }
  return AppWithDomain
}
